<template>
    <div class="itinerary-wrapper" :style="{height: `calc(100 * ${vh}px)`}">
        <div class="black d-flex align-center">
            <v-icon color="white" size="72">mdi-arrow-up</v-icon>
            <div class="d-flex flex-column ml-3">
              <span class="white--text text-h6">100m</span>  
              <span class="cyan--text text--lighten-1 text-h6">Rue de Cléry</span>  
            </div>
            <v-spacer></v-spacer>
            <v-btn class="mr-3" color="gray" @click="handleClose()" small>{{$t("itineraryArrivedBtn")}}</v-btn>
            <!-- <v-icon color="white" class="ma-4 align-self-start" size="32" >mdi-close</v-icon> -->
        </div>
        <div class="img-itinerary" :style="{backgroundImage: `url(${itineraryImg})`}">

        </div>
        <div class="white d-flex align-center itinerary-footer">
            <v-icon color="grey lighten-2" class="pa-4" size="48">mdi-magnify</v-icon>
            <v-divider vertical></v-divider>
            <div class="d-flex flex-column align-center arrival-time">
              <span class="text-h4 font-weight-bold">{{arrivalTime}}</span>  
              <span class="text-body-1 font-weight-bold">14 min<v-icon color="grey lighten-1">mdi-circle-small</v-icon>4.3 km</span>  
            </div>

        </div>

    </div>
</template>

<script>
    const moment = require("moment")
    export default {
        data(){
            return{
                page: {
                    title: "Rechercher une borne",
                    description: null,
                    showHeader: false
                },
                itineraryImg: require('@/assets/img/itinerary.png'),
                sequenceSent: null
            }
        },
        mounted(){
            let topic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.OCULAR_ACTION
            let message = {
                action: "goto",
                sequenceId: "1"
            }
            if(this.sequenceSent == null){
                this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                    console.log("Sequence 1 started")
                    this.sequenceSent = 0
                } )
            }
        },
        methods:{
            handleClose(){
                let topic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.OCULAR_ACTION
                let message = {
                    action: "goto",
                    sequenceId: "2"
                }
                this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                    console.log("Sequence 2 started")
                    this.$router.push({ name: 'Charging' })
                } )
                
            }
        },
        computed: {
            arrivalTime(){
                return moment().add(14, "minutes").format("HH:mm")
            }
        }
    }
</script>

<style scoped>
    .img-itinerary{
        background-position: center;
        background-size: cover;
    }
    .itinerary-wrapper{
        display: grid;
        grid-template-rows: 100px 1fr 100px;
    }
    .itinerary-footer{
        width: 100%
    }
    .itinerary-footer .arrival-time{
        width: 100%
    }
</style>